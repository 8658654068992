import React, { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import { useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import NiagaraLogo from "../../assets/Niagara.svg";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
	getCachedisCustomerUser,
	getCachedCustomerName,
	getCachedCustomerUsername,
} from "../../api/shipmentTable";

function Header({ accountInfo, onThemeChange, onLogout, open, setOpen }) {
	const theme = useTheme();
	const isDarkMode = theme.palette.mode === "dark";
	const [anchorEl, setAnchorEl] = useState(null);
	const [isOnline, setIsOnline] = useState(navigator.onLine);
	const offlineTimerRef = useRef(null);
	const inactivityTimerRef = useRef(null);

	// Get user type and details
	const isCustomerUser = getCachedisCustomerUser();
	// const customerName = getCachedCustomerName();
	const customerName = localStorage.getItem("customerName");
	// const customerUsername = getCachedCustomerUsername();
	const customerUsername = localStorage.getItem("username");

	// Log user details on component mount
	useEffect(() => {
		console.log("User Authentication Details:", {
			isCustomerUser,
			customerName,
			customerUsername,
			accountInfo: accountInfo?.account?.name,
			timestamp: new Date().toISOString(),
		});
	}, [isCustomerUser, customerName, customerUsername, accountInfo]);

	// Log authentication state changes
	useEffect(() => {
		if (isCustomerUser) {
			console.log("Customer User Authenticated:", {
				username: customerUsername,
				customerName,
				timestamp: new Date().toISOString(),
			});
		} else {
			console.log("Regular User Authenticated:", {
				username: accountInfo?.account?.name,
				timestamp: new Date().toISOString(),
			});
		}
	}, [isCustomerUser, customerUsername, customerName, accountInfo]);

	useEffect(() => {
		if (!open) {
			setAnchorEl(null);
		}
	}, [open]);

	useEffect(() => {
		const handleOnline = () => {
			console.log("Connection status: Online");
			setIsOnline(true);
			clearTimeout(offlineTimerRef.current);
			offlineTimerRef.current = null;
		};

		const handleOffline = () => {
			console.log("Connection status: Offline");
			setIsOnline(false);
			offlineTimerRef.current = setTimeout(
				() => {
					console.log("Logging out due to extended offline period");
					onLogout();
				},
				2 * 60 * 60 * 1000,
			);
		};

		const resetInactivityTimer = () => {
			clearTimeout(inactivityTimerRef.current);
			inactivityTimerRef.current = setTimeout(
				() => {
					console.log("Logging out due to inactivity");
					onLogout();
				},
				2 * 60 * 60 * 1000,
			);
		};

		const handleActivity = () => {
			resetInactivityTimer();
		};

		window.addEventListener("online", handleOnline);
		window.addEventListener("offline", handleOffline);
		window.addEventListener("mousemove", handleActivity);
		window.addEventListener("keydown", handleActivity);
		window.addEventListener("touchstart", handleActivity);

		resetInactivityTimer();

		return () => {
			window.removeEventListener("online", handleOnline);
			window.removeEventListener("offline", handleOffline);
			window.removeEventListener("mousemove", handleActivity);
			window.removeEventListener("keydown", handleActivity);
			window.removeEventListener("touchstart", handleActivity);
			clearTimeout(offlineTimerRef.current);
			clearTimeout(inactivityTimerRef.current);
		};
	}, [onLogout]);

	const handleMenuOpen = (event) => {
		console.log("Menu opened");
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		console.log("Menu closed");
		setAnchorEl(null);
	};

	const handleThemeChange = () => {
		console.log("Theme changed to:", isDarkMode ? "light" : "dark");
		onThemeChange();
		handleMenuClose();
	};

	const handleRegisterCustomer = () => {
		console.log("Register new customer dialog opened");
		setOpen(true);
	};

	const handleLogout = () => {
		console.log("User logged out:", {
			isCustomerUser,
			username: isCustomerUser ? customerUsername : accountInfo?.account?.name,
			timestamp: new Date().toISOString(),
		});
		onLogout();
	};

	// Determine what name to display
	const displayName = isCustomerUser ? customerUsername : accountInfo?.account?.name;
	const displayRole = isCustomerUser ? customerName : "User";

	return (
		<AppBar
			position="static"
			sx={{
				backgroundColor: theme.palette.background.default,
				color: theme.palette.text.primary,
				border: 1,
				borderColor: theme.palette.text.secondary,
			}}
		>
			<Toolbar
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					backgroundColor: theme.palette.background.default,
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
					<img src={NiagaraLogo} alt="Your SVG" style={{ width: "4em" }} />
					<Typography
						sx={{
							marginBottom: "1em",
							color: theme.palette.text.primary,
						}}
					>
						|
					</Typography>
					<Typography
						variant="h7"
						noWrap
						component="a"
						sx={{
							fontSize: "1rem",
							fontFamily: "Poppins, sans-serif",
							marginBottom: "0.6em",
							color: theme.palette.text.primary,
						}}
					>
						Shipment Live Tracking
					</Typography>
				</Box>
				<Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
					<PersonIcon />
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-start",
						}}
					>
						<Typography
							variant="body1"
							sx={{
								marginBottom: 0.5,
								fontSize: "1rem",
								fontFamily: "Poppins, sans-serif",
								color: "#006098",
							}}
						>
							{displayName}
						</Typography>
						<Typography
							variant="body2"
							sx={{
								marginBottom: 0.5,
								fontFamily: "Poppins, sans-serif",
								color: theme.palette.text.primary,
							}}
						>
							{displayRole}
						</Typography>
					</Box>
					<KeyboardArrowDownIcon data-testid="account-button" onClick={handleMenuOpen} />
					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleMenuClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
					>
						<MenuItem onClick={handleThemeChange}>
							{isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
						</MenuItem>
						{!isCustomerUser && (
							<MenuItem onClick={handleRegisterCustomer}>Register New Customer</MenuItem>
						)}
						<MenuItem onClick={handleLogout}>Logout</MenuItem>
					</Menu>
				</Box>
			</Toolbar>
		</AppBar>
	);
}

export default Header;
