import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../layouts/Layout";
import DashboardLayout from "../pages/DashboardPage/DashboardLayout";

const Navigation = ({ accountInfo, onThemeChange, onLogout }) => {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/"
					element={
						<Layout accountInfo={accountInfo} onThemeChange={onThemeChange} onLogout={onLogout}>
							<DashboardLayout
								accountInfo={accountInfo}
								onThemeChange={onThemeChange}
								onLogout={onLogout}
							/>
						</Layout>
					}
				/>
			</Routes>
		</BrowserRouter>
	);
};

export default Navigation;
