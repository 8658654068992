import axios from "axios";
import config from "./config";

const axiosInstance = axios.create({
	baseURL: config.baseURL,
	// Add any default headers or interceptors here
});

const _handleSuccess = (response) => response;
const _handleError = (error) => error;

/**
 * @description {Method to get data from the server}
 * @param url {String}
 * @returns Promise<unknown>
 */
const getRequest = (url) => axiosInstance.get(url).then(_handleSuccess).catch(_handleError);

/**
 * @description {Method to post data to the server}
 * @param url {String}
 * @param payload {unknown}
 * @returns Promise<unknown>
 */
const postRequest = (url, payload) =>
	axiosInstance.post(url, payload).then(_handleSuccess).catch(_handleError);

/**
 * @description {Method to put data to the server}
 * @param url {String}
 * @param payload {unknown}
 * @returns Promise<unknown>
 */
const putRequest = (url, payload) =>
	axiosInstance.put(url, payload).then(_handleSuccess).catch(_handleError);

/**
 * @description {Method to patch data to the server}
 * @param url {String}
 * @param payload {unknown}
 * @returns Promise<unknown>
 */
const patchRequest = (url, payload) =>
	axiosInstance.patch(url, payload).then(_handleSuccess).catch(_handleError);

/**
 * @description {Method to delete data from the server}
 * @param url {String}
 * @returns Promise<unknown>
 */
const deleteRequest = (url) => axiosInstance.delete(url).then(_handleSuccess).catch(_handleError);

export default axiosInstance;
export { getRequest, postRequest, putRequest, deleteRequest, patchRequest };
