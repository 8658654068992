import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { addUser } from "../../api/addUser";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialog-paper ": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

const NoValidateForm = styled("form")({
	"& input:invalid": {
		boxShadow: "none",
	},
});

export default function AddCustomerPopup(props) {
	const theme = useTheme();

	const [openSnackbar, setOpenSnackbar] = React.useState(false);
	const [snackBarSeverity, setSnackBarSeverity] = React.useState("success");
	const [snackbarMessage, setSnackbarMessage] = React.useState("");
	const [fieldErrors, setFieldErrors] = React.useState({
		username: false,
		email: false,
		customer: false,
	});

	const [userDetails, setUserDetails] = React.useState({
		username: "",
		email: "",
		customer: "",
	});

	const handleClose = () => {
		props.setOpen(false);
		setFieldErrors({
			username: false,
			email: false,
			customer: false,
		});
	};

	const handleChange = (attribute, value) => {
		setUserDetails({
			...userDetails,
			[attribute]: value,
		});
		setFieldErrors({
			...fieldErrors,
			[attribute]: false,
		});
	};

	const validateEmail = (email) => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(email);
	};

	const isNiagaraEmail = (email) => {
		return email.toLowerCase().endsWith("@niagarawater.com");
	};

	const validateFields = () => {
		// First Priority: Check mandatory fields
		const errors = {
			username: userDetails.username.trim() === "",
			email: userDetails.email.trim() === "",
			customer: userDetails.customer === "",
		};

		setFieldErrors(errors);

		if (Object.values(errors).some((error) => error)) {
			setSnackBarSeverity("error");
			setSnackbarMessage("Please fill all the mandatory fields");
			setOpenSnackbar(true);
			return false;
		}

		// Second Priority: Check email format
		if (!validateEmail(userDetails.email)) {
			setFieldErrors({
				...errors,
				email: true,
			});
			setSnackBarSeverity("error");
			setSnackbarMessage("Please enter a valid email address");
			setOpenSnackbar(true);
			return false;
		}

		// Third Priority: Check for Niagara email
		if (isNiagaraEmail(userDetails.email)) {
			setFieldErrors({
				...errors,
				email: true,
			});
			setSnackBarSeverity("error");
			setSnackbarMessage("The entered email address is Niagara User");
			setOpenSnackbar(true);
			return false;
		}

		return true;
	};

	const handleSubmit = async (e) => {
		if (e) {
			e.preventDefault();
		}

		if (!validateFields()) {
			return;
		}

		try {
			await addUser(userDetails);
			setSnackBarSeverity("success");
			setSnackbarMessage("Customer successfully added");
			setOpenSnackbar(true);
			handleClose();
		} catch (err) {
			if (err.response?.data?.message === "Customer with this email already exists") {
				setFieldErrors({
					...fieldErrors,
					email: true,
				});
				setSnackBarSeverity("error");
				setSnackbarMessage("Customer with this email already exists!");
				setOpenSnackbar(true);
			} else {
				setSnackBarSeverity("error");
				setSnackbarMessage("Failed to add customer. Please try again.");
				setOpenSnackbar(true);
			}
		}
	};

	React.useEffect(() => {
		if (props.open === true) {
			setUserDetails({
				username: "",
				email: "",
				customer: "",
			});
			setFieldErrors({
				username: false,
				email: false,
				customer: false,
			});
		}
	}, [props.open]);

	return (
		<React.Fragment>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={props.open}
			>
				<NoValidateForm onSubmit={handleSubmit} noValidate>
					<DialogTitle
						sx={{
							m: 0,
							padding: "0.5em 0em",
							fontWeight: 600,
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
						id="customized-dialog-title"
					>
						Register New Customer
						<IconButton
							aria-label="close"
							onClick={handleClose}
							sx={{
								color: theme.palette.grey[500],
								padding: "0px",
							}}
						>
							<CloseIcon />
						</IconButton>
					</DialogTitle>

					<DialogContent>
						<TextField
							fullWidth
							style={{ margin: "0.5em 0em", width: "33em" }}
							id="username-field"
							label="User Name"
							variant="outlined"
							required
							error={fieldErrors.username}
							value={userDetails.username}
							onChange={(event) => {
								handleChange("username", event.target.value);
							}}
							autoComplete="off"
							inputProps={{
								autoComplete: "off",
								form: {
									autoComplete: "off",
								},
							}}
						/>
						<TextField
							fullWidth
							style={{ margin: "0.5em 0em", width: "33em" }}
							id="email-field"
							label="Email ID"
							variant="outlined"
							type="text"
							required
							error={fieldErrors.email}
							value={userDetails.email}
							onChange={(event) => {
								handleChange("email", event.target.value);
							}}
							autoComplete="off"
							inputProps={{
								autoComplete: "off",
								form: {
									autoComplete: "off",
								},
							}}
						/>
						<FormControl
							fullWidth
							style={{ margin: "0.5em 0em", width: "33em" }}
							error={fieldErrors.customer}
						>
							<InputLabel id="customer-select-label">Customer Name *</InputLabel>
							<Select
								labelId="customer-select-label"
								id="customer-select"
								value={userDetails.customer}
								required
								label="Customer Name *"
								onChange={(event) => {
									handleChange("customer", event.target.value);
								}}
								autoComplete="off"
							>
								<MenuItem value={"Costco"}>Costco</MenuItem>
								<MenuItem value={"Kroger"}>Kroger</MenuItem>
								<MenuItem value={"BJ's"}>BJ's</MenuItem>
								{/* <MenuItem value={"Safeway"}>Safeway</MenuItem> */}
							</Select>
						</FormControl>
					</DialogContent>
					<DialogActions>
						<Button
							variant="text"
							style={{ borderRadius: "1em", color: theme.palette.text.primary }}
							onClick={handleClose}
							type="button"
						>
							Discard
						</Button>
						<Button
							variant="contained"
							style={{
								marginLeft: "2em",
								borderRadius: "2em",
								color: theme.palette.text.buttonhover,
								backgroundColor: theme.palette.button.main,
								"&:hover": {
									backgroundColor: theme.palette.buttonhover.main,
								},
							}}
							type="submit"
						>
							Submit
						</Button>
					</DialogActions>
				</NoValidateForm>
			</BootstrapDialog>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={3000}
				onClose={() => {
					setOpenSnackbar(false);
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert
					onClose={() => {
						setOpenSnackbar(false);
					}}
					severity={snackBarSeverity}
					variant="filled"
					sx={{
						width: "100%",
						backgroundColor: snackBarSeverity === "success" ? "#339900" : "#FF0000",
						color: "#ffffff",
					}}
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</React.Fragment>
	);
}
