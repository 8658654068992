import axiosInstance from "./axios";
import config from "./config";

export const updateShipment = async (payload) => {
	try {
		const response = await axiosInstance.put(config.endpoints.shipment_update_url, payload);
		return response.data;
	} catch (error) {
		console.error("Error updating shipment:", error);
		throw error;
	}
};
