import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { fetchMetrics } from "../../api/metrics";
import moment from "moment";
import { useTheme } from "@mui/material/styles";

export default function Metrics({
	selectedDate,
	CustomerName,
	CustomerRegion,
	DeliveryStatus,
	City,
	Warehouse,
	isSaveBtnClicked,
	setIsSaveBtnClicked,
	isApplyBtnClicked,
	setIsApplyBtnClicked,
	reschedule,
}) {
	const [expanded, setExpanded] = useState(false);
	const [metricsData, setMetricsData] = useState(null);
	const theme = useTheme();

	const handleAccordionToggle = () => {
		setExpanded(!expanded);
	};

    const fetchData = async () => {
        try {
            const datesToUse =
                selectedDate && selectedDate.length > 0 ? selectedDate : [moment().format("YYYYMMDD")];
            const data = await fetchMetrics(
                datesToUse,
                CustomerName,
                CustomerRegion,
                DeliveryStatus,
                City,
                Warehouse,
                reschedule,
            );
            setMetricsData(data);
            setIsSaveBtnClicked(false);
            setIsApplyBtnClicked(false);
        } catch (error) {
            console.error("Error fetching metrics:", error);
            setMetricsData(null);
            setIsApplyBtnClicked(false);
        }
    };

	const cardData = [
		{ title: "TOTAL", value: metricsData?.total },
		{ title: "PENDING PICKUP", value: metricsData?.pendingPickup },
		{ title: "IN TRANSIT", value: metricsData?.intransit },
		{ title: "ON TIME", value: metricsData?.ontime },
		{ title: "LATE", value: metricsData?.late },
		{ title: "MISSED", value: metricsData?.missed },
		{ title: "RESCHEDULED", value: metricsData?.rescheduled }, 
	];

    useEffect(() => {
        if(isSaveBtnClicked || isApplyBtnClicked) {
            fetchData();
        }
	}, [isSaveBtnClicked, isApplyBtnClicked]);

	return (
		<Accordion
			expanded={expanded}
			onChange={handleAccordionToggle}
			sx={{
				position: "fixed",
				bottom: 0,
				width: "100%",
				background: theme.palette.background.default,
				'& .MuiAccordionSummary-root': {
					minHeight: '40px', 
					'& .MuiAccordionSummary-content': {
						margin: '8px 0', 
					}
				}
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandLessIcon />}
				aria-controls="panel1-content"
				id="panel1-header"
			>
				<Typography
					sx={{
						fontFamily: "Poppins, sans-serif",
						fontSize: '14px', 
					}}
				>
					Metrics
				</Typography>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					padding: '10px', 
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						gap: "12px", 
						padding: "10px", 
						background: theme.palette.background.default,
					}}
				>
					{cardData.map((data, index) => (
						<Card
							key={index}
							sx={{
								display: "flex",
								flexDirection: "column",
								flex: "1 0 auto",
								textAlign: "center",
								width: "10%",
								border: theme.palette.border.main,
								height: '60px', 
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									height: "100%",
									background: theme.palette.background.default,
								}}
							>
								<CardContent
									sx={{
										padding: '8px',
										'&:last-child': {
											paddingBottom: '8px' 
										}
									}}
								>
									<Typography
										component="div"
										color={theme.palette.text.primary}
										sx={{
											fontFamily: "Poppins, sans-serif",
											color: theme.palette.text.primary,
											background: theme.palette.background.default,
											fontSize: '12px',
											marginBottom: '2px' 
										}}
									>
										{data.title}:
									</Typography>
									<Typography
										sx={{
											fontFamily: "Poppins, sans-serif",
											color: theme.palette.text.primary,
											background: theme.palette.background.default,
											fontSize: '16px', 
										}}
										variant="subtitle1"
										color={theme.palette.text.primary}
										component="div"
									>
										{data?.value}
									</Typography>
								</CardContent>
							</Box>
						</Card>
					))}
				</Box>
			</AccordionDetails>
		</Accordion>
	);
}
