import React, { useState, useEffect } from "react";
import {
	Drawer,
	Box,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Checkbox,
	FormControlLabel,
	TextField,
	Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { fetchCustomers } from "../../api/customers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { fetchCustomerRegions } from "../../api/customerRegions";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import { getCachedisCustomerUser } from "../../api/shipmentTable";
import CustomDateRangePicker from "./CustomDateRangePicker";
import { FILTER_ACTIONS, FILTER_MSG, regionMap, SAVE_BUTTON_GROUP_OPTIONS } from "../../constants/ShipmentConstants";
import ReusableButtonGroup from "../Reusables/ReusableButtonGroup";
import SettingsIcon from '@mui/icons-material/Settings';
import { saveFilters } from "../../api/users";
import { useToastContext } from "../../context/toast";

const Sidebar = ({ isOpen, toggleDrawer, onApplyFilters, initialDate, accountInfo, currentFilters }) => {
	const theme = useTheme();
    const { showSuccessToast, showErrorToast } = useToastContext();
	const isCustomerUser = getCachedisCustomerUser();
	const customerName = localStorage.getItem("customerName");

	const [customers, setCustomers] = useState([]);
	const [selectedCustomerId, setSelectedCustomerId] = useState(null);
	const [selectedCustomerName, setSelectedCustomerName] = useState(
		isCustomerUser ? customerName : null,
	);
	const [customerRegions, setCustomerRegions] = useState([]);
	const [selectedCustomerRegions, setSelectedCustomerRegions] = useState([]);
	const [loadingRegions, setLoadingRegions] = useState(false);
	const [startDate, setStartDate] = useState(dayjs(initialDate));
	const [endDate, setEndDate] = useState(dayjs(initialDate));
	const [selectedDates, setSelectedDates] = useState([]);
	const [selectedDeliveryStatus, setSelectedDeliveryStatus] = useState(null);
	const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);
	const [city, setCity] = useState("");
	const [warehouse, setWarehouse] = useState("");
	const [availableDates, setAvailableDates] = useState([]);
	const [warehouseError, setWarehouseError] = useState("");
	const [isMissedSelected, setIsMissedSelected] = useState(false);
	const [isRescheduleNeeded, setIsRescheduleNeeded] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        setSelectedCustomerRegions(currentFilters?.CustomerRegion ? currentFilters.CustomerRegion.split(","): []);
	    setSelectedDeliveryStatus(currentFilters?.DeliveryStatus ? currentFilters.DeliveryStatus : null);
        setIsMissedSelected(currentFilters?.DeliveryStatus === "Missed");
        setCity(currentFilters?.City ? currentFilters.City: "");
        setWarehouse(currentFilters?.Warehouse ? currentFilters.Warehouse : "");
        setIsRescheduleNeeded(currentFilters?.reschedule ? currentFilters.reschedule : false);
    }, [currentFilters]);

	// Update available dates based on current time
	useEffect(() => {
		const updateAvailableDates = () => {
			const currentDate = new Date();
			const currentHour = currentDate.getHours();
			const dates = [];

			const now = dayjs(currentDate);

			dates.push(now);

			if (currentHour >= 5) {
				dates.push(now.add(1, "day"));
				for (let i = 1; i <= 12; i++) {
					dates.push(now.subtract(i, "day"));
				}
			} else {
				for (let i = 1; i <= 13; i++) {
					dates.push(now.subtract(i, "day"));
				}
			}

			setAvailableDates(dates);
		};

		updateAvailableDates();
		const interval = setInterval(updateAvailableDates, 60000);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
        // for any customer user
		const initializeCustomerUser = async () => {
			if (isCustomerUser && customerName) {
				try {
					const customersData = await fetchCustomers();
					const customerRecord = customersData.find(
						(customer) => customer.name.toLowerCase() === customerName.toLowerCase(),
					);

					if (customerRecord) {
						setSelectedCustomerId(customerRecord.id);
						setSelectedCustomerName(customerName);

						setLoadingRegions(true);
						try {
							const regions = await fetchCustomerRegions(customerRecord.id);
							setCustomerRegions(
								Array.isArray(regions)
									? customerRecord.id === "1"
										? regions.sort(
												(a, b) =>
													Object.keys(regionMap).indexOf(a.region) -
													Object.keys(regionMap).indexOf(b.region),
											)
										: regions
									: [],
							);
						} catch (error) {
							console.error("Error fetching regions for customer user:", error);
							setCustomerRegions([]);
						} finally {
							setLoadingRegions(false);
						}
					}
				} catch (error) {
					console.error("Error fetching customer data:", error);
				}
			}
		};

		initializeCustomerUser();
	}, [isCustomerUser, customerName]);

	useEffect(() => {
        // for niagara users only
		const loadCustomers = async () => {
			if (!isCustomerUser) {
				try {
					const data = await fetchCustomers();
					setCustomers(data);
                    const customerRecord = data.find(
						(customer) => customer.name.toLowerCase() === currentFilters?.CustomerName?.toLowerCase(),
					);
                    if (customerRecord) {
                        setSelectedCustomerId(customerRecord.id);
					    setSelectedCustomerName(currentFilters.CustomerName);
                        currentFilters.CustomerName && await getRegionsForCustomer(customerRecord.id, currentFilters.CustomerName)
                        currentFilters?.CustomerRegion && setSelectedCustomerRegions(currentFilters.CustomerRegion.split(","))
                    }
				} catch (error) {
					console.error("Error fetching customers:", error);
					setCustomers([]);
				}
			}
		};

		loadCustomers();
	}, [isCustomerUser, currentFilters]);

	useEffect(() => {
		const initialDateObj = dayjs(initialDate);
		setStartDate(initialDateObj);
		setEndDate(initialDateObj);
		setSelectedDates([initialDateObj]);
	}, [initialDate]);

	useEffect(() => {
		const calculateAppliedFiltersCount = () => {
			let count = 0;
            startDate?.format("YYYY-MM-DD") && endDate?.format("YYYY-MM-DD") && count++;
			selectedDeliveryStatus && count++;
			selectedCustomerId && !isCustomerUser && count++;
			(selectedCustomerRegions.length > 0) && count++;
			city && count++;
			warehouse && count++;
			setAppliedFiltersCount(count);
		};

		calculateAppliedFiltersCount();
	}, [
		selectedDeliveryStatus,
		startDate,
		endDate,
		selectedCustomerId,
		selectedCustomerRegions,
		city,
		warehouse,
		isCustomerUser,
		isMissedSelected,
		
	]);

    const getRegionsForCustomer = async(customerId, customerName) => {
        setLoadingRegions(true);
        try {
            setSelectedCustomerId(customerId);
            setSelectedCustomerName(customerName);
            setSelectedCustomerRegions([]);

            const regions = await fetchCustomerRegions(customerId);
            setCustomerRegions(
                Array.isArray(regions)
                    ? customerId === "1"
                        ? regions.sort(
                                (a, b) =>
                                    Object.keys(regionMap).indexOf(a.region) -
                                    Object.keys(regionMap).indexOf(b.region),
                            )
                        : regions
                    : [],
            );
        } catch (error) {
            console.error("Error while fetching regions:", error);
            setCustomerRegions([]);
        }
        setLoadingRegions(false);
    };

	const handleCustomerChange = async (customerId, customerName) => {
		if (isCustomerUser) return;

		if (selectedCustomerId === customerId) {
			setSelectedCustomerId(null);
			setSelectedCustomerName(null);
			setSelectedCustomerRegions([]);
			setCustomerRegions([]);
		} else {
			await getRegionsForCustomer(customerId, customerName);
		}
	};

	const handleCustomerRegionChange = (region) => {
		const index = selectedCustomerRegions.indexOf(region);
		if (index === -1) {
			setSelectedCustomerRegions([...selectedCustomerRegions, region]);
		} else {
			setSelectedCustomerRegions(selectedCustomerRegions.filter((r) => r !== region));
		}
	};

	const handleRescheduleNeededChange = () => {
		// Toggle the reschedule needed state
		setIsRescheduleNeeded(!isRescheduleNeeded);
	};

	const handleDeliveryStatusChange = (status) => {
		if (selectedDeliveryStatus === status) {
			setSelectedDeliveryStatus(null);
			setIsMissedSelected(false);
			setIsRescheduleNeeded(false); // Reset reschedule when deselecting status
		} else {
			setSelectedDeliveryStatus(status);
			if (status === "Missed") {
				setIsMissedSelected(true);
			} else {
				setIsMissedSelected(false);
				setIsRescheduleNeeded(false); // Reset reschedule when selecting non-Missed status
			}
		}
	};

	const handleDateRangeChange = (newStartDate, newEndDate, dateRange) => {
		setStartDate(newStartDate);
		setEndDate(newEndDate);
		setSelectedDates(dateRange);
	};

	const handleCityChange = (event) => {
		const value = event.target.value;
		if (value.startsWith(" ")) {
			return;
		}
		setCity(value);
	};

	const handleWarehouseChange = (event) => {
		const value = event.target.value;
		if (value.startsWith(" ")) {
			return;
		}
		setWarehouse(value);
		setWarehouseError(""); // Clear any previous error when typing
	};

	const validateWarehouse = () => {
		// Regex to check for special characters except comma
		const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|.<>/?]+/;

		if (warehouse && specialCharRegex.test(warehouse)) {
			setWarehouseError("Enter valid Warehouse(s)");
			return false;
		}

		setWarehouseError("");
		return true;
	};

	const validateDateRange = () => {
		if (!startDate || !endDate) {
			return false;
		}
		// Check if dates are in available dates
		const isStartDateValid = availableDates.some(
			(availableDate) => startDate.format("YYYY-MM-DD") === availableDate.format("YYYY-MM-DD"),
		);
		const isEndDateValid = availableDates.some(
			(availableDate) => endDate.format("YYYY-MM-DD") === availableDate.format("YYYY-MM-DD"),
		);
		if (!isStartDateValid || !isEndDateValid) {
			return false;
		}
		// Check if start date is before or equal to end date
		if (startDate.isAfter(endDate)) {
			return false;
		}
		return true;
	};

	const resetFilterStates = () => {
		const currentDate = new Date();
		const currentDayjs = dayjs(currentDate);
		setSelectedDeliveryStatus(null);
		setStartDate(currentDayjs);
		setEndDate(currentDayjs);
		setSelectedDates([currentDayjs]);
		setSelectedCustomerRegions([]);
		setCity("");
		setWarehouse("");
        setWarehouseError("");
		setIsMissedSelected(false);
		setIsRescheduleNeeded(false);

		if (!isCustomerUser) {
			setSelectedCustomerId(null);
			setSelectedCustomerName(null);
		}
    };
    const getDefaultFilters = () => {
        const currentDate = new Date();
		const filters = {
			SelectedDate: moment(currentDate).format("YYYYMMDD"),
			CustomerName: isCustomerUser ? customerName : null,
			CustomerRegion: null,
			DeliveryStatus: null,
			City: null,
			Warehouse: null,
			AppliedFiltersCount: 1,
			isCustomerUser: isCustomerUser,
			reschedule: false,
		};
        return filters;
	};

    const handleClearFilters = () => {
        if (isSaving){
            return;
        }
        resetFilterStates();
        onApplyFilters(getDefaultFilters());
		toggleDrawer();
	};

    const validateAndGetCurrentFilters = () => {
        // Validate date range and Warehouse field before applying filters
		if (!validateDateRange() || !validateWarehouse()) {
			return;
		}

		const formattedDates = selectedDates.map((date) => date.format("YYYYMMDD")).join(",");
		const filters = {
			SelectedDate: formattedDates,
			CustomerName: isCustomerUser ? customerName : selectedCustomerName,
			CustomerRegion: selectedCustomerRegions.length > 0 ? selectedCustomerRegions.join(",") : null,
			DeliveryStatus: selectedDeliveryStatus || null,
			City: city || null,
			Warehouse: warehouse || null,
			AppliedFiltersCount: appliedFiltersCount,
			isCustomerUser: isCustomerUser,
			reschedule: isRescheduleNeeded,
		};
        return filters;
    };

	const handleApplyFilters = () => {
        if (isSaving) {
            return;
        }
        const filters = validateAndGetCurrentFilters();
        if (filters) {
            onApplyFilters(filters);
		    toggleDrawer();
        }
	};

	const handleSaveFilters = async (option) => {
        if(isSaving) {
            return;
        }
        setIsSaving(true);
        const payload = {
            email: accountInfo.account.userName,
            userName: accountInfo.account.name,
            filters: "",
        };

        if(option === FILTER_ACTIONS.saveFilter) {
            const filters = validateAndGetCurrentFilters();
            if (!filters) {
                setIsSaving(false);
                return;
            }
            delete filters.isCustomerUser;
            delete filters.AppliedFiltersCount;
            delete filters.SelectedDate;
            payload.filters = JSON.stringify(filters);
        } else {
            resetFilterStates();
        }
        const response = await saveFilters(payload);
        response === null ? showErrorToast(FILTER_MSG.error) : showSuccessToast(
            option === FILTER_ACTIONS.saveFilter ? FILTER_MSG.save_success :FILTER_MSG.reset_success
        );
        setIsSaving(false);
	};

	return (
		<>
            <Drawer
                    anchor="right"
                    open={isOpen}
                    onClose={toggleDrawer}
                    PaperProps={{
                        sx: {
                            borderTopLeftRadius: "8px",
                            borderBottomLeftRadius: "8px",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        },
                    }}
                >
                    <Box
                        sx={{
                            width: 350,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "100%",
                            position: "relative",
                            overflow: "auto",
                        }}
                    >
                        <Box 
                            sx={{
                                marginBottom: "10px"
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontSize: "28px",
                                        fontWeight: 400,
                                        lineHeight: "36px",
                                        letterSpacing: "0px",
                                        textAlign: "left",
                                        margin: "20px",
                                    }}
                                    variant="h6"
                                    gutterBottom
                                >
                                    Filters
                                </Typography>
                                <Button
                                    disabled={isSaving}
                                    variant="text"
                                    sx={{
                                        textTransform: "none",
                                        color: theme.palette.text.primary,
                                        marginRight: "16px",
                                        fontFamily: "Poppins, sans-serif",
                                    }}
                                    onClick={handleClearFilters}
                                >
                                    Clear Filters
                                </Button>
                            </Box>
        
                            {
                                isSaving ? <Typography sx={{ textAlign: "center", padding: "16px" }}>Saving...</Typography> 
                                : (
                                    <>
                                        {!isCustomerUser && (
                                        <Accordion
                                            sx={{
                                                border: "1px solid #E8F2FF",
                                                "&.Mui-expanded": {
                                                    margin: 0,
                                                },
                                            }}
                                        >
                                            <AccordionSummary
                                                sx={{ height: "50px" }}
                                                expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.text.primary }} />}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins, sans-serif",
                                                        fontWeight: selectedCustomerId ? "bold" : "normal",
                                                    }}
                                                >
                                                    Customer
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    padding: 0,
                                                    "& .MuiFormControlLabel-root": {
                                                        margin: 0,
                                                    },
                                                }}
                                            >
                                                {customers && customers.length > 0 ? (
                                                    customers.map((customer) => (
                                                        <FormControlLabel
                                                            key={customer.id}
                                                            control={
                                                                <Checkbox
                                                                    sx={{
                                                                        color: "#006098",
                                                                        "&.Mui-checked": { color: "#006098" },
                                                                    }}
                                                                    checked={selectedCustomerId === customer.id}
                                                                    onChange={() => handleCustomerChange(customer.id, customer.name)}
                                                                />
                                                            }
                                                            label={
                                                                <Typography sx={{ fontFamily: "Poppins, sans-serif" }}>
                                                                    {customer.name}
                                                                </Typography>
                                                            }
                                                            sx={{ borderBottom: "1px solid #E8F2FF" }}
                                                        />
                                                    ))
                                                ) : (
                                                    <Typography sx={{ fontFamily: "Poppins, sans-serif", p: 2 }}>
                                                        No customers available
                                                    </Typography>
                                                )}
                                                </AccordionDetails>
                                            </Accordion>
                                        )}
                
                                        <Accordion
                                            sx={{
                                                border: "1px solid #E8F2FF",
                                                "&.Mui-expanded": {
                                                    margin: 0,
                                                },
                                            }}
                                            disabled={loadingRegions || (!selectedCustomerId && !isCustomerUser)}
                                        >
                                            <AccordionSummary
                                                sx={{ height: "50px" }}
                                                expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.text.primary }} />}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins, sans-serif",
                                                        fontWeight: selectedCustomerRegions.length > 0 ? "bold" : "normal",
                                                    }}
                                                >
                                                    Region
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    padding: 0,
                                                    "& .MuiFormControlLabel-root": {
                                                        margin: 0,
                                                    },
                                                }}
                                            >
                                                {loadingRegions ? (
                                                    <Typography sx={{ fontFamily: "Poppins, sans-serif", p: 2 }}>
                                                        Loading regions...
                                                    </Typography>
                                                ) : customerRegions && customerRegions.length > 0 ? (
                                                    customerRegions.map((region) => (
                                                        <FormControlLabel
                                                            key={region.id}
                                                            control={
                                                                <Checkbox
                                                                    sx={{
                                                                        color: "#006098",
                                                                        "&.Mui-checked": { color: "#006098" },
                                                                    }}
                                                                    checked={selectedCustomerRegions.includes(region.region)}
                                                                    onChange={() => handleCustomerRegionChange(region.region)}
                                                                />
                                                            }
                                                            label={
                                                                <Typography sx={{ fontFamily: "Poppins, sans-serif" }}>
                                                                    {region.customerId === "1"
                                                                        ? (regionMap[region.region] ?? region.region)
                                                                        : region.region}
                                                                </Typography>
                                                            }
                                                            sx={{ borderBottom: "1px solid #E8F2FF" }}
                                                        />
                                                    ))
                                                ) : (
                                                    <Typography sx={{ fontFamily: "Poppins, sans-serif", p: 2 }}>
                                                        No regions available
                                                    </Typography>
                                                )}
                                            </AccordionDetails>
                                        </Accordion>
                                        <Box
                                            sx={{
                                                padding: "16px",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins, sans-serif",
                                                    fontWeight: city ? "bold" : "normal",
                                                    marginBottom: "8px",
                                                }}
                                            >
                                                Enter City
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                placeholder="City"
                                                value={city}
                                                onChange={handleCityChange}
                                                sx={{
                                                    fontFamily: "Poppins, sans-serif",
                                                    color: theme.palette.text.primary,
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": {
                                                            borderColor: theme.palette.border.main,
                                                        },
                                                        "&:hover fieldset": {
                                                            borderColor: theme.palette.border.main,
                                                        },
                                                        "&.Mui-focused fieldset": {
                                                            borderColor: theme.palette.FocusStyle.main,
                                                        },
                                                        "& fieldset": {
                                                            border: theme.palette.border.main,
                                                        },
                                                    },
                                                }}
                                            />
                                        </Box>
                                        <Accordion
                                            sx={{
                                                border: "1px solid #E8F2FF",
                                                marginBottom: "10px",
                                                "&.Mui-expanded": {
                                                    margin: 0,
                                                },
                                            }}
                                        >
                                            <AccordionSummary
                                                sx={{ height: "50px" }}
                                                expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.text.primary }} />}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Poppins, sans-serif",
                                                        fontWeight: selectedDeliveryStatus ? "bold" : "normal",
                                                    }}
                                                >
                                                    Delivery Status
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    padding: 0,
                                                    "& .MuiFormControlLabel-root": {
                                                        margin: 0,
                                                    },
                                                }}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            sx={{
                                                                color: "#006098",
                                                                "&.Mui-checked": { color: "#006098" },
                                                            }}
                                                            checked={selectedDeliveryStatus === "Intransit"}
                                                            onChange={() => handleDeliveryStatusChange("Intransit")}
                                                        />
                                                    }
                                                    label={
                                                        <Typography sx={{ fontFamily: "Poppins, sans-serif" }}>In Transit</Typography>
                                                    }
                                                    sx={{ borderBottom: "1px solid #E8F2FF" }}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            sx={{
                                                                color: "#006098",
                                                                "&.Mui-checked": { color: "#006098" },
                                                            }}
                                                            checked={selectedDeliveryStatus === "Pending Pickup"}
                                                            onChange={() => handleDeliveryStatusChange("Pending Pickup")}
                                                        />
                                                    }
                                                    label={
                                                        <Typography sx={{ fontFamily: "Poppins, sans-serif" }}>Pending Pickup</Typography>
                                                    }
                                                    sx={{ borderBottom: "1px solid #E8F2FF" }}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            sx={{
                                                                color: "#006098",
                                                                "&.Mui-checked": { color: "#006098" },
                                                            }}
                                                            checked={selectedDeliveryStatus === "On Time"}
                                                            onChange={() => handleDeliveryStatusChange("On Time")}
                                                        />
                                                    }
                                                    label={<Typography sx={{ fontFamily: "Poppins, sans-serif" }}>On Time</Typography>}
                                                    sx={{ borderBottom: "1px solid #E8F2FF" }}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            sx={{
                                                                color: "#006098",
                                                                "&.Mui-checked": { color: "#006098" },
                                                            }}
                                                            checked={selectedDeliveryStatus === "Late"}
                                                            onChange={() => handleDeliveryStatusChange("Late")}
                                                        />
                                                    }
                                                    label={<Typography sx={{ fontFamily: "Poppins, sans-serif" }}>Late</Typography>}
                                                    sx={{ borderBottom: "1px solid #E8F2FF" }}
                                                />
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "flex-start",
                                                        borderBottom: "1px solid #E8F2FF",
                                                        // paddingRight: "16px",
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                sx={{
                                                                    color: "#006098",
                                                                    "&.Mui-checked": { color: "#006098" },
                                                                }}
                                                                checked={selectedDeliveryStatus === "Missed"}
                                                                onChange={() => handleDeliveryStatusChange("Missed")}
                                                            />
                                                        }
                                                        label={<Typography sx={{ fontFamily: "Poppins, sans-serif" }}>Missed</Typography>}
                                                    />
                                                    {!isCustomerUser && (
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "flex-start",
                                                                width: "100%",
                                                                paddingLeft: "2.5rem",
                                                                borderTop: "1px solid #E8F2FF",
                                                            }}
                                                        >
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        sx={{
                                                                            color: "#006098",
                                                                            "&.Mui-checked": { color: "#006098" },
                                                                        }}
                                                                        checked={isRescheduleNeeded}
                                                                        onChange={handleRescheduleNeededChange}
                                                                        disabled={!isMissedSelected}
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography sx={{ fontFamily: "Poppins, sans-serif" }}>
                                                                        Reschedule Needed
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Box>
                                                    )}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Box
                                            sx={{
                                                padding: "16px",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins, sans-serif",
                                                    fontWeight: warehouse ? "bold" : "normal",
                                                    marginBottom: "8px",
                                                }}
                                            >
                                                Warehouse
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                placeholder="Warehouse "
                                                value={warehouse}
                                                onChange={handleWarehouseChange}
                                                error={!!warehouseError}
                                                helperText={warehouseError}
                                                sx={{
                                                    fontFamily: "Poppins, sans-serif",
                                                    color: theme.palette.text.primary,
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": {
                                                            borderColor: warehouseError ? "red" : theme.palette.border.main,
                                                        },
                                                        "&:hover fieldset": {
                                                            borderColor: warehouseError ? "red" : theme.palette.border.main,
                                                        },
                                                        "&.Mui-focused fieldset": {
                                                            borderColor: warehouseError ? "red" : theme.palette.FocusStyle.main,
                                                        },
                                                        "& fieldset": {
                                                            border: warehouseError ? "1px solid red" : theme.palette.border.main,
                                                        },
                                                    },
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                paddingLeft: "20px",
                                                paddingRight: "20px",
                                                marginTop: "20px",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins, sans-serif",
                                                    fontWeight: startDate && endDate ? "bold" : "normal",
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                Select Delivery Appointment
                                            </Typography>
                    
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <CustomDateRangePicker
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    onDateChange={handleDateRangeChange}
                                                    availableDates={availableDates}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    </>
                                )
                            }
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "20px",
                        }}
                    >
                        <ReusableButtonGroup
                            disabled={isSaving}
                            options={SAVE_BUTTON_GROUP_OPTIONS}
                            icon={SettingsIcon} 
                            onSelect={handleSaveFilters}
                            defaultIndex={0}
                        />
                        <Button
                            disabled={isSaving}
                            variant="text"
                            sx={{
                                textTransform: "none",
                                color: theme.palette.text.primary,
                                fontFamily: "Poppins, sans-serif",
                            }}
                            onClick={toggleDrawer}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={isSaving}
                            variant="contained"
                            color="primary"
                            sx={{
                                textTransform: "none",
                                borderRadius: "80px",
                                bgcolor: theme.palette.button.main,
                                "&:hover": { bgcolor: theme.palette.buttonhover.main },
                                fontFamily: "Poppins, sans-serif",
                            }}
                            onClick={handleApplyFilters}
                        >
                            Apply
                        </Button>
                    </Box>
            </Drawer>
        </>
	);
};

export default Sidebar;
