export const COMMENT_MAPPING_USING_REASONCODE = {
	"ADMINISTRATIVE LOAD PLANNING": "Carrier Failure",
	"CPU RESCHEDULED": "Carrier Failure",
	"CUSTOMER ADJUSTMENT": "Customer Adjustment",
	"CARRIER SERVICE FAILURE": "Carrier Failure",
	"CSR RESCHEDULE - SPOT BID OVER THRESHOLD": "System Issue",
	"CUSTOMER CANCELLED": "Customer Adjustment",
	"INFEASIBLE TRANSIT": "System Issue",
	"LACK OF RAW MATERIALS": "Loading Delay",
	"LOGISTICS OPERATION ERROR": "System Issue",
	"NIAGARA SCHEDULING ERROR": "System Issue",
	"NO DOCK SPACE": "System Issue",
	"NON-OPTIMAL ORG CHANGE": "Loading Delay",
	"NO PRODUCT": "Loading Delay",
	"QUALITY HOLD": "Loading Delay",
	"SHIPPING FAILURE DELETE": "Loading Delay",
	"SYSTEM ISSUE DELETE": "System Issue",
	"TRANSFER FAILURE": "Loading Delay",
	"WEATHER": "Weather Conditions",
	"CUSTOMER REFUSAL": "Customer Refusal",
	"LOADING DELAY": "Loading Delay",
	"UNLOADING DELAY": "Customer Adjustment",
};

export const regionMap = {
	"Region 1": "1 - Los Angeles",
	"Region 2": "2 - San Diego",
	"Region 3": "3 - Bay Area",
	"Region 4": "4 - Northeast",
	"Region 5": "5 - Southeast",
	"Region 6": "6 - Midwest",
	"Region 7": "7 - Texas",
	"Region 8": "8 - Northwest",
	"Costco Business Centers": "9 - Business Centers",
};

export const FILTER_ACTIONS = {
    saveFilter: "Save Filters",
    reset: "Reset to Default",
};

export const FILTER_MSG = {
    save_success: "Filters saved successfully",
    reset_success: "Filters reset to default",
    error: "Could not update filters. Please try again!",
}

export const SAVE_BUTTON_GROUP_OPTIONS = [ FILTER_ACTIONS.saveFilter, FILTER_ACTIONS.reset];
