import { convertDateStringToArray } from "../utilities/shipmentTable";
import axiosInstance from "./axios";
import config from "./config";
import moment from "moment";

export const fetchMetrics = async (
	selectedDate,
	CustomerName,
	CustomerRegion,
	DeliveryStatus,
	City,
	Warehouse,
	reschedule,
) => {
	try {
		const isCustomerLoggedIn = localStorage.getItem("isCustomerUser") === "true";

		const dateArray = convertDateStringToArray(selectedDate) || [moment().format("YYYYMMDD")];
		
		const response = await axiosInstance.get(`${config.endpoints.metrics_url}`, {
			params: {
				SelectedDate: dateArray,
				CustomerName: CustomerName || "",
				CustomerRegion: CustomerRegion || "",
				DeliveryStatus: DeliveryStatus || "",
				City: City || "",
				isCustomerLoggedIn: isCustomerLoggedIn,
				Warehouse: Warehouse || "",
				reschedule: reschedule || false,
			},
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching metrics:", error);
		throw error;
	}
};
