import axiosInstance from "./axios";
import config from "./config";

export const getUsers = async () => {
	try {
		const response = await axiosInstance.get(config.endpoints.users);
		return response.data;
	} catch (error) {
		console.error("Error while fetching users:", error);
		throw error;
	}
};

export async function getFilters(userSelector, userName) {
    try {
		const response = await axiosInstance.get(config.endpoints.filters + "?email=" + userSelector + "&userName=" + userName);
		return response.data;
	} catch (error) {
		console.error("Error while fetching filters:", error);
        return null;
	}
}

export async function saveFilters(data) {
    try {
		const response = await axiosInstance.put(config.endpoints.filters, data);
		return response.data;
	} catch (error) {
		console.error("Error while saving filters:", error);
        return null;
	}
}
