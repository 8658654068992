import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useTheme } from "@mui/material/styles";

const ReusableButtonGroup = ({ options, disabled, icon: Icon, onSelect, defaultIndex = 0 }) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(defaultIndex);

    const handleClick = () => {
        if (onSelect) {
            onSelect(options[selectedIndex]);
        }
    };

    const handleMenuItemClick = (event, index) => {
        if (onSelect) {
            onSelect(options[index + 1]); // Adjust index for the filtered options
        }
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <React.Fragment>
            <ButtonGroup
                disabled={disabled}
                variant="outlined" // Use outlined variant for borders
                ref={anchorRef}
                aria-label="Button group with a nested menu"
                sx={{
                    fontFamily: "Poppins, sans-serif",
                    textTransform: 'none',
                    "& .MuiButton-outlined": {
                        border: theme.palette.border.main,
                        color: theme.palette.text.primary, // Set text color for buttons
                        "&:hover": {
                            border: theme.palette.border.main,
                        },
                        "&.Mui-focused": {
                            border: theme.palette.FocusStyle.main,
                        },
                    },
                }}
            >
                <Button onClick={handleClick} sx={{ textTransform: 'none' }} disabled={disabled}>
                    {options[selectedIndex]}
                </Button>
                <Button
                    disabled={disabled}
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select option"
                    aria-haspopup="menu"
                    sx={{
                        fontFamily: "Poppins, sans-serif",
                        textTransform: 'none',
                        "& .MuiButton-outlined": {
                            border: theme.palette.border.main,
                            color: theme.palette.text.primary, // Set text color for buttons
                            "&:hover": {
                                border: theme.palette.border.main,
                            },
                            "&.Mui-focused": {
                                border: theme.palette.FocusStyle.main,
                            },
                        },
                    }}
                    onClick={handleToggle}
                >
                    {Icon ? <Icon /> : <ArrowDropDownIcon />}
                </Button>
            </ButtonGroup>
            <Popper
                sx={{ zIndex: 1 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options
                                        .filter((_, index) => index !== 0) // Hide the first option from the dropdown
                                        .map((option, index) => (
                                            <MenuItem
                                                style={{fontSize: "0.875rem", padding: "0 20px"}}
                                                key={option}
                                                onClick={(event) => handleMenuItemClick(event, index)} // Adjust index for the filtered options
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
};


export default ReusableButtonGroup;