import axiosInstance from "./axios";
import config from "./config";

export const fetchCustomers = async () => {
	try {
		const response = await axiosInstance.get(config.endpoints.customers_url);
		return response.data;
	} catch (error) {
		console.error("Error fetching customers:", error);
		throw error;
	}
};
