// AuthenticatedRoute.js
import React, { useState, useEffect } from "react";
import { AuthenticationState } from "react-aad-msal";

import NavigationContainer from "../navigation/Navigation";
import { getUsers } from "../api/users";
import { clearAll } from "../utilities/localstorage";

const AuthenticatedRoute = ({
	logout,
	authenticationState,
	error,
	accountInfo,
	onThemeChange,
}) => {
	const [authError, setAuthError] = useState(null);
	const [isCustomerCheckComplete, setIsCustomerCheckComplete] = useState(false);

	const isNiagaraEmail = (email) => {
		return email.toLowerCase().endsWith("@niagarawater.com");
	};

	useEffect(() => {
		const checkCustomerStatus = async () => {
			// Check if the customer status is already stored
			const isCustomerUserStored = localStorage.getItem("isCustomerUser ");
			// If customer status is already stored, skip the check
			if (isCustomerUserStored !== null) {
				setIsCustomerCheckComplete(true);
				return;
			}

			if (
				authenticationState === AuthenticationState.Authenticated &&
				accountInfo?.account?.userName
			) {
				try {
					const users = await getUsers();

					const userEmail = accountInfo.account.userName.toLowerCase();

					// First check if it's a Niagara employee
					if (isNiagaraEmail(userEmail)) {
						localStorage.setItem("isCustomerUser", "false");
						localStorage.setItem("customerName", "");
						setAuthError(null);
					} else {
						// If not a Niagara employee, check if they're a valid customer
						const userRecord = users.find((user) => user.email.toLowerCase() === userEmail);

						if (userRecord?.customer) {
							localStorage.setItem("isCustomerUser", "true");
							localStorage.setItem("customerName", userRecord.customer);
							localStorage.setItem("username", userRecord.username);
							setAuthError(null);
						} else {
							// User is neither a Niagara employee nor a recognized customer
							console.error("User  not authorized");
							setAuthError("Sorry, but we are having trouble signing you in");
							logout(); // Force logout for unauthorized users
							return; // Exit early
						}
					}
				} catch (error) {
					console.error("Error checking customer status:", error);
					setAuthError("Sorry, but we are having trouble signing you in");
					logout(); // Force logout on error
					return; // Exit early
				}
			}

			setIsCustomerCheckComplete(true); // Mark the check as complete
		};

		if (authenticationState === AuthenticationState.Authenticated) {
			checkCustomerStatus();
		}

		// Cleanup function
		return () => {
			if (authenticationState !== AuthenticationState.Authenticated) {
				localStorage.removeItem("isCustomerUser");
				localStorage.removeItem("customerName");
			}
		};
	}, []);

	return (
		<NavigationContainer
				accountInfo={accountInfo}
				onLogout={() => {
					clearAll();
					logout();
				}}
				onThemeChange={onThemeChange}
			/>
	)
};

export default AuthenticatedRoute;
