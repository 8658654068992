import React from "react";
import { Box } from "@mui/material";
import DashboardOverview from "../../components/DashboardScreens/DashboardOverview";
import ReusableTabs from "../../components/Reusables/ReusableTabs";

const DashboardLayout = ({ accountInfo, onThemeChange, onLogout }) => {
	const tabs = [
		{
			label: "Overview",
			content: (
				<DashboardOverview
					accountInfo={accountInfo}
					onThemeChange={onThemeChange}
					onLogout={onLogout}
				/>
			),
		},
		// {
		//   label: "Metrics",
		//   content: "",
		// },
		// {
		//   label: "Manage Customer",
		//   content: "",
		// },
	];

	return (
		<Box sx={{ padding: "0px", margin: "0px" }}>
			<ReusableTabs tabs={tabs} defaultIndex={0} />
		</Box>
	);
};

export default DashboardLayout;
