import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import Header from "../components/Header/Header";
import AddCustomerPopup from "../components/AddCustomerPopup";

const Layout = ({ accountInfo, onThemeChange, onLogout, children }) => {
	const theme = useTheme();
	const [isOnline, setIsOnline] = useState(navigator.onLine);
	const [open, setOpen] = React.useState(false); // state to toggle opening and closing of add customer popup

	useEffect(() => {
		const handleOnline = () => {
			setIsOnline(true);
		};

		const handleOffline = () => {
			setIsOnline(false);
		};

		window.addEventListener("online", handleOnline);
		window.addEventListener("offline", handleOffline);

		return () => {
			window.removeEventListener("online", handleOnline);
			window.removeEventListener("offline", handleOffline);
		};
	}, []);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				minHeight: "100vh",
				background: theme.palette.background.default,
				overflow: "hidden",
			}}
		>
			<Header
				accountInfo={accountInfo}
				onThemeChange={onThemeChange}
				onLogout={onLogout}
				open={open}
				setOpen={setOpen}
			/>
			{!isOnline && (
				<Box
					sx={{
						backgroundColor: "error.main",
						color: "error.contrastText",
						padding: "10px",
						textAlign: "center",
						position: "sticky",
						top: 0,
						zIndex: 1000,
					}}
				>
					Network not available. Please try Again Later.
				</Box>
			)}
			<Box sx={{ padding: "0px", margin: "0px" }}>{children}</Box>
			<AddCustomerPopup open={open} setOpen={setOpen} />
		</Box>
	);
};

export default Layout;
