import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";

export default function Export({ open, handleClose }) {
	const [exportFormat, setExportFormat] = React.useState("xlsx"); // Default format
	const theme = useTheme();

	const handleFormatChange = (event) => {
		setExportFormat(event.target.value);
	};

	const handleConfirm = () => {
		handleClose(exportFormat);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle
				id="alert-dialog-title"
				sx={{
					fontFamily: "Poppins",
					fontSize: 28,
					fontWeight: 400,
					lineHeight: "36px",
					letterSpacing: 0,
					textAlign: "left",
				}}
			>
				{"Export"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText
					id="alert-dialog-description"
					sx={{
						fontFamily: "Poppins",
						fontSize: 16,
						fontWeight: 400,
						lineHeight: "24px",
						letterSpacing: 0.5,
						textAlign: "left",
						color: theme.palette.text.primary,
						margin: "16px 20px",
					}}
				>
					The table will be exported. If any filters are applied, then the filtered data table will
					be exported, and if no filters are applied, then the whole table will be exported.
				</DialogContentText>
				<Grid container direction="row" justifyContent="center">
					<RadioGroup
						aria-label="export-format"
						name="export-format"
						value={exportFormat}
						onChange={handleFormatChange}
						sx={{
							flexDirection: "row",
						}}
					>
						<FormControlLabel
							value="xlsx"
							control={
								<Radio
									sx={{
										color: theme.palette.text.primary,
										"&.Mui-checked": {
											color: theme.palette.text.primary,
										},
									}}
								/>
							}
							label="Excel (.xlsx)"
							sx={{
								fontFamily: "Poppins",
							}}
						/>
						<FormControlLabel
							value="csv"
							control={
								<Radio
									sx={{
										color: theme.palette.text.primary,
										"&.Mui-checked": {
											color: theme.palette.text.primary,
										},
									}}
								/>
							}
							label="Comma separated value (.csv)"
							sx={{
								fontFamily: "Poppins",
							}}
						/>
					</RadioGroup>
				</Grid>
			</DialogContent>
			<DialogActions sx={{ margin: "16px 20px" }}>
				<Button
					sx={{ textTransform: "none", color: theme.palette.text.primary }}
					onClick={() => handleClose(null)}
				>
					Cancel
				</Button>
				<Button
					onClick={handleConfirm}
					autoFocus
					sx={{
						backgroundColor: theme.palette.button.main,
						color: theme.palette.text.buttonhover,
						borderRadius: "80px",
						textTransform: "none",
						"&:hover": {
							backgroundColor: theme.palette.buttonhover.main,
						},
					}}
				>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
